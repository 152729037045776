<template>
    <div class="content w-full mt-[-10px]">
        <div class="flex items-center justify-between max-lg:pl-5 pr-5">
            <ul class="inline-flex text-sm font-medium text-center overflow-auto whitespace-nowrap" id="dashboard-tab"
                data-tabs-toggle="#dashboard-page-content" data-tabs-active-classes="text-base-text-color border-pf-yellow"
                data-tabs-inactive-classes="text-black-100 border-none" role="tablist">
                <li class="me-5" role="presentation">
                    <button class="inline-block h-[60px] px-2 border-b-2 rounded-t-lg" data-tabs-target="#tab-1"
                        type="button" role="tab">Dashboard</button>
                </li>
            </ul>

            <RouterLink to="/"
                class="flex-none inline-flex items-center justify-center text-black-100 text-[14px] max-lg:hidden">
                <span class="flex items-center justify-center p-1 text-xl text-base-text-color rounded mx-2 bg-black-200">
                    <BxSolidChevronLeft />
                </span>
                Back
            </RouterLink>
        </div>

        <div id="dashboard-page-content" class="py-5">
            <div class="hidden grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 max-lg:gap-5 gap-10"
                id="tab-1" role="tabpanel">
                <!-- Balance -->
                <div class="w-full bg-black-600 rounded-lg overflow-hidden" style="min-height: 300px;">
                    <div class="flex bg-black-400 px-5 py-3 rounded-lg items-center justify-between">
                        <h5 class="font-[500] text-base-text-color">Balance</h5>
                    </div>
                    <div class="flow-root">
                    </div>
                </div>

                <!-- Recently Played -->
                <div class="w-full bg-black-600 rounded-lg  overflow-hidden">
                    <div class="flex bg-black-400 px-5 py-3 rounded-lg items-center justify-between">
                        <h5 class="font-[500] text-base-text-color">Recently Played</h5>
                    </div>
                    <div class="flow-root">
                        <ul role="list" class="divide-y divide-black-400">
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <img src="../assets/temp/temp-1.png" class="h-50">
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-pf-green">10 Free Card</p>
                                    </div>
                                    <div
                                        class="inline-flex items-center justify-center font-bold text-sm text-pf-green">
                                        <button type="button"
                                            class="text-base-text-color bg-black-200 hover:bg-pf-yellow hover:text-black-900 rounded-full text-xl px-2 py-2">
                                            <BxSolidChevronRight />
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <img src="../assets/temp/temp-2.png" class="h-50">
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-pf-green">10 Free Spin</p>
                                    </div>
                                    <div
                                        class="inline-flex items-center justify-center font-bold text-sm text-pf-green">
                                        <button type="button"
                                            class="text-base-text-color bg-black-200 hover:bg-pf-yellow hover:text-black-900 rounded-full text-xl px-2 py-2">
                                            <BxSolidChevronRight />
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <img src="../assets/temp/temp-3.png" class="h-50">
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Prediction Games</p>
                                        <p class="text-sm text-pf-green">10 Free Spin</p>
                                    </div>
                                    <div
                                        class="inline-flex items-center justify-center font-bold text-sm text-pf-green">
                                        <button type="button"
                                            class="text-base-text-color bg-black-200 hover:bg-pf-yellow hover:text-black-900 rounded-full text-xl px-2 py-2">
                                            <BxSolidChevronRight />
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li class="flex-1 h-[51px]">
                                <a href=""
                                    class="flex w-100 h-full align-middle items-center justify-center bg-black-500 text-sm text-pf-yellow hover:bg-pf-yellow hover:text-black-900 text-center font-bold">See
                                    all games</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- My Biggest Wins -->
                <div class="w-full bg-black-600 rounded-lg">
                    <div class="flex bg-black-400 px-5 py-3 rounded-lg items-center justify-between">
                        <h5 class="font-[500] text-base-text-color">My Biggest Wins </h5>
                    </div>
                    <div class="flow-root">
                        <ul role="list" class="divide-y divide-black-400">
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <BsCardText />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-black-100">Deposit</p>
                                    </div>
                                    <div class="inline-flex items-center font-bold text-sm text-pf-green">$372,930
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <LuFerrisWheel />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-black-100">Deposit</p>
                                    </div>
                                    <div class="inline-flex items-center font-bold text-sm text-pf-green">$25,500
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <LuFerrisWheel />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-black-100">Deposit</p>
                                    </div>
                                    <div class="inline-flex items-center font-bold text-sm text-pf-green">$11,172
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <BsCardText />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-black-100">Deposit</p>
                                    </div>
                                    <div class="inline-flex items-center font-bold text-sm text-pf-green">$5,224
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- My Promotions -->
                <div class="w-full bg-black-600 rounded-lg">
                    <div class="flex bg-black-400 px-5 py-3 rounded-lg items-center justify-between">
                        <h5 class="font-[500] text-base-text-color">My Promotions</h5>
                    </div>
                    <div class="flow-root">
                        <ul role="list" class="divide-y divide-black-400">
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <BsCardText />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-pf-green">10 Free Card</p>
                                    </div>
                                    <div
                                        class="inline-flex items-center justify-center font-bold text-sm text-pf-green">
                                        <button type="button"
                                            class="text-base-text-color bg-black-200 hover:bg-pf-yellow hover:text-black-900 rounded-lg text-sm px-3 py-2.5">Learn
                                            More</button>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <LuFerrisWheel />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-pf-green">10 Free Spin</p>
                                    </div>
                                    <div
                                        class="inline-flex items-center justify-center font-bold text-sm text-pf-green">
                                        <button type="button"
                                            class="text-base-text-color bg-black-200 hover:bg-pf-yellow hover:text-black-900 rounded-lg text-sm px-3 py-2.5">Learn
                                            More</button>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <LuFerrisWheel />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-pf-green">10 Free Spin</p>
                                    </div>
                                    <div
                                        class="inline-flex items-center justify-center font-bold text-sm text-pf-green">
                                        <button type="button"
                                            class="text-base-text-color bg-black-200 hover:bg-pf-yellow hover:text-black-900 rounded-lg text-sm px-3 py-2.5">Learn
                                            More</button>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <BsCardText />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-pf-green">10 Free Card</p>
                                    </div>
                                    <div
                                        class="inline-flex items-center justify-center font-bold text-sm text-pf-green">
                                        <button type="button"
                                            class="text-base-text-color bg-black-200 hover:bg-pf-yellow hover:text-black-900 rounded-lg text-sm px-3 py-2.5">Learn
                                            More</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Tournament Placements  -->
                <div class="w-full bg-black-600 rounded-lg">
                    <div class="flex bg-black-400 px-5 py-3 rounded-lg items-center justify-between">
                        <h5 class="font-[500] text-base-text-color">Tournament Placements</h5>
                    </div>
                    <div class="flow-root">
                        <ul role="list" class="divide-y divide-black-400">
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <FlFilledCrown />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-black-100">Tournament 457</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-base-text-color">279th</p>
                                        <p class="text-sm text-pf-yellow">On-going</p>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <FlFilledCrown />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-black-100">Tournament 455</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-base-text-color">279th</p>
                                        <p class="text-sm text-pf-yellow">On-going</p>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <FlFilledCrown />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-black-100">Tournament 438</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-base-text-color">279th</p>
                                        <p class="text-sm text-pf-green">Completed</p>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <FlFilledCrown />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-black-100">Tournament 427</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-base-text-color">279th</p>
                                        <p class="text-sm text-pf-green">Completed</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Latest Transactions  -->
                <div class="w-full bg-black-600 rounded-lg">
                    <div class="flex bg-black-400 px-5 py-3 rounded-lg items-center justify-between">
                        <h5 class="font-[500] text-base-text-color">Latest Transactions</h5>
                    </div>
                    <div class="flow-root">
                        <ul role="list" class="divide-y divide-black-400">
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <BsCardText />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-black-100">Deposit</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-black-100">11.04.2023</p>
                                        <p class="text-sm text-pf-green">Accepted</p>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <LuFerrisWheel />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-black-100">Free Spin</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-black-100">11.04.2023</p>
                                        <p class="text-sm text-pf-red">Rejected</p>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <LuFerrisWheel />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Fortune Wheel</p>
                                        <p class="text-sm text-black-100">Deposit</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-black-100">11.04.2023</p>
                                        <p class="text-sm text-pf-green">Accepted</p>
                                    </div>
                                </div>
                            </li>
                            <li class="px-5 py-5">
                                <div class="flex items-center">
                                    <div class="flex-shrink-0 me-4">
                                        <span
                                            class="flex  items-center justify-center text-2xl w-[40px] h-[40px] text-base-text-color bg-black-300 rounded">
                                            <BsCardText />
                                        </span>
                                    </div>
                                    <div class="flex-1 truncate">
                                        <p class="text-sm font-medium text-base-text-color">Scratch Card</p>
                                        <p class="text-sm text-black-100">Tournament 427</p>
                                    </div>
                                    <div class="font-bold text-sm text-pf-green text-right *:q">
                                        <p class="text-sm font-bold text-black-100">01.04.2023</p>
                                        <p class="text-sm text-pf-green">Accepted</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Level -->
                <div class="w-full bg-black-600 rounded-lg  overflow-hidden" style="min-height: 300px;">
                    <div class="flex bg-black-400 px-5 py-3 rounded-lg items-center justify-between">
                        <h5 class="font-[500] text-base-text-color">Next Level</h5>
                    </div>
                    <div class="flow-root">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dashboard',
    components: {
    },
}
</script>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

// importing icons as SVG code
import { BxSolidChevronLeft } from '@kalimahapps/vue-icons';
import { BxSolidChevronRight } from '@kalimahapps/vue-icons';
import { LuFerrisWheel } from '@kalimahapps/vue-icons';
import { BsCardText } from '@kalimahapps/vue-icons';
import { FlFilledCrown } from '@kalimahapps/vue-icons';

onMounted(() => {
    initFlowbite();
})
</script>

<style scoped></style>