<template>
    <div class="content w-full mt-[-10px]">
        <div class="flex items-center justify-between max-lg:pl-5 pr-5">
            <ul class="inline-flex text-sm font-medium text-center overflow-auto whitespace-nowrap"
                id="rediction-games-tab" data-tabs-toggle="#prediction-games-page-content"
                data-tabs-active-classes="text-base-text-color border-pf-yellow"
                data-tabs-inactive-classes="text-black-100 border-none" role="tablist">
                <li class="me-5" role="presentation">
                    <button class="inline-block h-[60px] px-2 border-b-2 rounded-t-lg" data-tabs-target="#tab-1"
                        type="button" role="tab">Play</button>
                </li>

                <li class="me-5" role="presentation">
                    <button class="inline-block h-[60px] px-2 border-b-2 rounded-t-lg" data-tabs-target="#tab-2"
                        type="button" role="tab">Leaderboard</button>
                </li>

                <li class="me-5" role="presentation">
                    <button class="inline-block h-[60px] px-2 border-b-2 rounded-t-lg" data-tabs-target="#tab-3"
                        type="button" role="tab">Rules & FAQ</button>
                </li>
            </ul>

            <RouterLink to="/"
                class="flex-none inline-flex items-center justify-center text-black-100 text-[14px] max-lg:hidden">
                <span
                    class="flex items-center justify-center p-1 text-xl text-base-text-color rounded mx-2 bg-black-200">
                    <BxSolidChevronLeft />
                </span>
                Back
            </RouterLink>
        </div>

        <div id="prediction-games-page-content" class="py-5">
            <div class="hidden" id="tab-1" role="tabpanel">
                <div class="flex items-center justify-between py-3 mb-5">
                    <!-- Page Title -->
                    <h5 class="text-base-text-color text-[20px] font-[500] max-sm:text-[16px]">PREDICTION GAMES</h5>

                    <!-- Filter Button -->
                    <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown"
                        class="bg-black-400 hover:bg-black-200 text-base-text-color rounded-md text-sm px-5 py-2.5 text-center inline-flex items-center"
                        type="button">Filter
                        <BxSolidChevronDown class="ml-2" />
                    </button>

                    <!-- Filter Dropdown -->
                    <div id="dropdown" class="z-10 hidden bg-black-500 rounded-lg shadow">
                        <ul class="py-2 px-2 text-sm text-base-text-color" aria-labelledby="dropdownDefaultButton">
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-black-300 rounded-md">A-Z</a>
                            </li>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-black-300 rounded-md">Free Games</a>
                            </li>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-black-300 rounded-md">Newest</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Game Cards -->
                <div
                    class="grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2 max-[480px]:grid-cols-1 max-lg:gap-5 gap-10">
                    <!-- Game Card -->
                    <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                        <div class="flow-root">
                            <img src="../assets/temp/temp-pr-games-1.png" class="w-[100%]">
                            <div class="game-card-content">
                                <div
                                    class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                    Round 18</div>
                                <a
                                    class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                    <BsPhoneFlip />
                                </a>
                                <div
                                    class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                    Ending in 1d 00:15:37
                                    <BxSolidTime class="ml-1" />
                                </div>
                            </div>
                        </div>

                        <div class="flow-back hidden">
                            <img src="../assets/temp/temp-pr-games-1.png" class="w-[100%] blur-sm">
                            <div class="game-card-content"></div>
                        </div>
                    </div>

                    <!-- Game Card -->
                    <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                        <div class="flow-root">
                            <img src="../assets/temp/temp-pr-games-2.png" class="w-[100%]">
                            <div class="game-card-content">
                                <div
                                    class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                    Round 18</div>
                                <a
                                    class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                    <BsPhoneFlip />
                                </a>
                                <div
                                    class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                    Ending in 1d 00:15:37
                                    <BxSolidTime class="ml-1" />
                                </div>
                            </div>
                        </div>

                        <div class="flow-back hidden">
                            <img src="../assets/temp/temp-pr-games-2.png" class="w-[100%] blur-sm">
                            <div class="game-card-content"></div>
                        </div>
                    </div>

                    <!-- Game Card -->
                    <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                        <div class="flow-root">
                            <img src="../assets/temp/temp-pr-games-3.png" class="w-[100%]">
                            <div class="game-card-content">
                                <div
                                    class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                    Round 18</div>
                                <a
                                    class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                    <BsPhoneFlip />
                                </a>
                                <div
                                    class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                    Ending in 1d 00:15:37
                                    <BxSolidTime class="ml-1" />
                                </div>
                            </div>
                        </div>

                        <div class="flow-back hidden">
                            <img src="../assets/temp/temp-pr-games-3.png" class="w-[100%] blur-sm">
                            <div class="game-card-content"></div>
                        </div>
                    </div>

                    <!-- Game Card -->
                    <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                        <div class="flow-root">
                            <img src="../assets/temp/temp-pr-games-4.png" class="w-[100%]">
                            <div class="game-card-content">
                                <div
                                    class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                    Round 18</div>
                                <a
                                    class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                    <BsPhoneFlip />
                                </a>
                                <div
                                    class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                    Ending in 1d 00:15:37
                                    <BxSolidTime class="ml-1" />
                                </div>
                            </div>
                        </div>

                        <div class="flow-back hidden">
                            <img src="../assets/temp/temp-pr-games-4.png" class="w-[100%] blur-sm">
                            <div class="game-card-content"></div>
                        </div>
                    </div>

                    <!-- Game Card -->
                    <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                        <div class="flow-root">
                            <img src="../assets/temp/temp-pr-games-5.png" class="w-[100%]">
                            <div class="game-card-content">
                                <div
                                    class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                    Round 18</div>
                                <a
                                    class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                    <BsPhoneFlip />
                                </a>
                                <div
                                    class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                    Ending in 1d 00:15:37
                                    <BxSolidTime class="ml-1" />
                                </div>
                            </div>
                        </div>

                        <div class="flow-back hidden">
                            <img src="../assets/temp/temp-pr-games-5.png" class="w-[100%] blur-sm">
                            <div class="game-card-content"></div>
                        </div>
                    </div>

                    <!-- Game Card -->
                    <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                        <div class="flow-root">
                            <img src="../assets/temp/temp-pr-games-6.png" class="w-[100%]">
                            <div class="game-card-content">
                                <div
                                    class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                    Round 18</div>
                                <a
                                    class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                    <BsPhoneFlip />
                                </a>
                                <div
                                    class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                    Ending in 1d 00:15:37
                                    <BxSolidTime class="ml-1" />
                                </div>
                            </div>
                        </div>

                        <div class="flow-back hidden">
                            <img src="../assets/temp/temp-pr-games-6.png" class="w-[100%] blur-sm">
                            <div class="game-card-content"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="hidden" id="tab-2" role="tabpanel">
                <div class="flex items-center justify-between py-3 mb-5">
                    <!-- Page Title -->
                    <h5 class="text-base-text-color text-[20px] font-[500] max-sm:text-[16px]">LEADERBOARD</h5>
                </div>
            </div>

            <div class="hidden" id="tab-3" role="tabpanel">
                <div class="flex items-center justify-between py-3 mb-5">
                    <!-- Page Title -->
                    <h5 class="text-base-text-color text-[20px] font-[500] max-sm:text-[16px]">RULES & FAQ</h5>
                </div>

                <div class="flex">
                    <div id="accordion-collapse" class="w-[100%] rounded-md" data-accordion="collapse">
                        <!-- Item -->
                        <h2 id="accordion-collapse-heading-1" class="bg-black-500 rounded-md">
                            <button type="button" class="flex items-center justify-between text-base-text-color text-[16px] w-full p-5"
                                data-accordion-target="#accordion-collapse-body-1">
                                <span>How football betting works?</span>
                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/></svg>
                            </button>
                        </h2>
                        <div id="accordion-collapse-body-1" class="bg-black-500 rounded-md mt-[-10px] hidden">
                            <div class="p-5 text-base-text-color">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil</p>
                            </div>
                        </div>
                        
                        <div class="mb-3"></div>

                        <!-- Item -->
                        <h2 id="accordion-collapse-heading-2" class="bg-black-500 rounded-md">
                            <button type="button" class="flex items-center justify-between text-base-text-color text-[16px] w-full p-5"
                                data-accordion-target="#accordion-collapse-body-2">
                                <span>How to win more freespins?</span>
                                <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/></svg>
                            </button>
                        </h2>
                        <div id="accordion-collapse-body-2" class="bg-black-500 rounded-md mt-[-10px] hidden">
                            <div class="p-5 text-base-text-color">
                                <p>Eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis  minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit quibusdam sed amet tempora. Sit laborum ab, eius fugit doloribus tenetur fugiat, temporibus enim commodi iusto libero magni deleniti quod quam consequuntur! Commodi minima excepturi repudiandae velit hic maxime doloremque. Quaerat provident commodi consectetur veniam similique ad earum omnis ipsum saepe, voluptas, hic voluptates pariatur est explicabo fugiat, dolorum eligendi quam cupiditate excepturi.</p>
                            </div>
                        </div>                        
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PredictionGames',
    components: {
    },
}
</script>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

// importing icons as SVG code
import { BxSolidChevronLeft } from '@kalimahapps/vue-icons';
import { BxSolidTime } from '@kalimahapps/vue-icons';
import { BsPhoneFlip } from '@kalimahapps/vue-icons';
import { BxSolidChevronDown } from '@kalimahapps/vue-icons';

onMounted(() => {
    initFlowbite();

    const cards = document.querySelectorAll(".game-card");

    function flipCardAnimation() {
        cards.forEach((card) => {
            if (card != this) {
                card.classList.remove('is-flipped');
            }
        });

        this.classList.toggle('is-flipped');
    }

    cards.forEach((card) => card.addEventListener("click", flipCardAnimation));
})
</script>

<style scoped>
.game-card {
    transition: transform .5s;
    transform-style: preserve-3d;
}

.game-card.is-flipped {
    transform: rotateY(180deg);
}

.game-card.is-flipped .flow-back {
    display: block;
}

.game-card.is-flipped .flow-root {
    display: none;
}

.card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
}

.card__face--front {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>