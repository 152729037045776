<template>
    <div class="content w-full mt-[-10px]">
        <div class="flex items-center justify-between max-lg:pl-5 pr-5">
            <ul class="inline-flex text-sm font-medium text-center overflow-auto whitespace-nowrap"
                id="rediction-games-tab" data-tabs-toggle="#prediction-games-page-content"
                data-tabs-active-classes="text-base-text-color border-pf-yellow"
                data-tabs-inactive-classes="text-black-100 border-none" role="tablist">
                <li class="me-5" role="presentation">
                    <button class="inline-block h-[60px] px-2 border-b-2 rounded-t-lg" data-tabs-target="#tab-1"
                        type="button" role="tab">Play</button>
                </li>

                <li class="me-5" role="presentation">
                    <button class="inline-block h-[60px] px-2 border-b-2 rounded-t-lg" data-tabs-target="#tab-2"
                        type="button" role="tab">Leaderboard</button>
                </li>

                <li class="me-5" role="presentation">
                    <button class="inline-block h-[60px] px-2 border-b-2 rounded-t-lg" data-tabs-target="#tab-3"
                        type="button" role="tab">Rules & FAQ</button>
                </li>
            </ul>

            <RouterLink to="/"
                class="flex-none inline-flex items-center justify-center text-black-100 text-[14px] max-lg:hidden">
                <span
                    class="flex items-center justify-center p-1 text-xl text-base-text-color rounded mx-2 bg-black-200">
                    <BxSolidChevronLeft />
                </span>
                Back
            </RouterLink>
        </div>

        <div id="prediction-games-page-content" class="py-5">
            <div class="hidden grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2  max-[480px]:grid-cols-1 max-lg:gap-5 gap-10"
                id="tab-1" role="tabpanel">
                <!-- Game Card -->
                <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                    <div class="flow-root">
                        <img src="../assets/temp/temp-pr-games-1.png" class="w-[100%]">
                        <div class="game-card-content">
                            <div
                                class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                Round 18</div>
                            <a
                                class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                <BsPhoneFlip />
                            </a>
                            <div
                                class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                Ending in 1d 00:15:37
                                <BxSolidTime class="ml-1" />
                            </div>
                        </div>
                    </div>

                    <div class="flow-back hidden">
                        <img src="../assets/temp/temp-pr-games-1.png" class="w-[100%] blur-sm">
                        <div class="game-card-content"></div>
                    </div>
                </div>

                <!-- Game Card -->
                <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                    <div class="flow-root">
                        <img src="../assets/temp/temp-pr-games-2.png" class="w-[100%]">
                        <div class="game-card-content">
                            <div
                                class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                Round 18</div>
                            <a
                                class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                <BsPhoneFlip />
                            </a>
                            <div
                                class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                Ending in 1d 00:15:37
                                <BxSolidTime class="ml-1" />
                            </div>
                        </div>
                    </div>

                    <div class="flow-back hidden">
                        <img src="../assets/temp/temp-pr-games-2.png" class="w-[100%] blur-sm">
                        <div class="game-card-content"></div>
                    </div>
                </div>

                <!-- Game Card -->
                <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                    <div class="flow-root">
                        <img src="../assets/temp/temp-pr-games-3.png" class="w-[100%]">
                        <div class="game-card-content">
                            <div
                                class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                Round 18</div>
                            <a
                                class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                <BsPhoneFlip />
                            </a>
                            <div
                                class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                Ending in 1d 00:15:37
                                <BxSolidTime class="ml-1" />
                            </div>
                        </div>
                    </div>

                    <div class="flow-back hidden">
                        <img src="../assets/temp/temp-pr-games-3.png" class="w-[100%] blur-sm">
                        <div class="game-card-content"></div>
                    </div>
                </div>

                <!-- Game Card -->
                <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                    <div class="flow-root">
                        <img src="../assets/temp/temp-pr-games-4.png" class="w-[100%]">
                        <div class="game-card-content">
                            <div
                                class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                Round 18</div>
                            <a
                                class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                <BsPhoneFlip />
                            </a>
                            <div
                                class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                Ending in 1d 00:15:37
                                <BxSolidTime class="ml-1" />
                            </div>
                        </div>
                    </div>

                    <div class="flow-back hidden">
                        <img src="../assets/temp/temp-pr-games-4.png" class="w-[100%] blur-sm">
                        <div class="game-card-content"></div>
                    </div>
                </div>

                <!-- Game Card -->
                <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                    <div class="flow-root">
                        <img src="../assets/temp/temp-pr-games-5.png" class="w-[100%]">
                        <div class="game-card-content">
                            <div
                                class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                Round 18</div>
                            <a
                                class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                <BsPhoneFlip />
                            </a>
                            <div
                                class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                Ending in 1d 00:15:37
                                <BxSolidTime class="ml-1" />
                            </div>
                        </div>
                    </div>

                    <div class="flow-back hidden">
                        <img src="../assets/temp/temp-pr-games-5.png" class="w-[100%] blur-sm">
                        <div class="game-card-content"></div>
                    </div>
                </div>

                <!-- Game Card -->
                <div class="game-card w-full rounded-lg cursor-pointer overflow-hidden relative">
                    <div class="flow-root">
                        <img src="../assets/temp/temp-pr-games-6.png" class="w-[100%]">
                        <div class="game-card-content">
                            <div
                                class="round py-2 px-2 bg-gray-800/50 rounded-md absolute top-[10px] left-[10px] text-[12px] text-gray-100">
                                Round 18</div>
                            <a
                                class="card-flip absolute top-[5px] right-[5px] px-2 py-2 cursor-pointer text-2xl text-gray-100 hover:text-pf-yellow">
                                <BsPhoneFlip />
                            </a>
                            <div
                                class="chrono-time flex items-center justify-center py-2 px-2 bg-gray-800/50 rounded-md w-[calc(100%-20px)] absolute bottom-[10px] left-[10px] text-[12px] text-gray-100">
                                Ending in 1d 00:15:37
                                <BxSolidTime class="ml-1" />
                            </div>
                        </div>
                    </div>

                    <div class="flow-back hidden">
                        <img src="../assets/temp/temp-pr-games-6.png" class="w-[100%] blur-sm">
                        <div class="game-card-content"></div>
                    </div>
                </div>
            </div>

            <div class="hidden grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 max-lg:gap-5 gap-10" id="tab-2"
                role="tabpanel">
                <p class="text-base-text-color">Leaderboard</p>
            </div>

            <div class="hidden grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 max-lg:gap-5 gap-10" id="tab-3"
                role="tabpanel">
                <p class="text-base-text-color">Rules & FAQ</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PredictionGames',
    components: {
    },
}
</script>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'

// importing icons as SVG code
import { BxSolidChevronLeft } from '@kalimahapps/vue-icons';
import { BxSolidTime } from '@kalimahapps/vue-icons';
import { BsPhoneFlip } from '@kalimahapps/vue-icons';

onMounted(() => {
    initFlowbite();

    const cards = document.querySelectorAll(".game-card");

    function flipCardAnimation() {
        cards.forEach((card) => {
            if (card != this) {
                card.classList.remove('is-flipped');
            }
        });

        this.classList.toggle('is-flipped');
    }

    cards.forEach((card) => card.addEventListener("click", flipCardAnimation));
})
</script>

<style scoped>
.game-card {
    transition: transform .5s;
    transform-style: preserve-3d;
}

.game-card.is-flipped {
    transform: rotateY(180deg);
}

.game-card.is-flipped .flow-back {
    display: block;
}

.game-card.is-flipped .flow-root {
    display: none;
}

.card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
}

.card__face--front {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>