import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/views/Dashboard.vue'
import PredictionGames from '@/views/PredictionGames.vue'
import Tournaments from '@/views/Tournaments.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
    },
    {
        path: '/prediction-games',
        name: 'PredictionGames',
        component: PredictionGames
    },
    {
        path: '/tournaments',
        name: 'Tournaments',
        component: Tournaments
    },
]

const router = createRouter({ history: createWebHistory(), routes })
export default router