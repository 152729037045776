<template>
  <ul class="space-y-2 font-medium">
    <!-- Menu Item -->
    <li>
      <a class="flex items-center h-[40px] p-3 text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg cursor-pointer"
        aria-controls="dd-games" data-collapse-toggle="dd-games" data-tooltip-target="tooltip-games"
        data-tooltip-placement="right">
        <span class="flex-none text-xl mr-3 text-base-text-color">
          <MdRoundGamepad />
        </span>
        <span class="flex-1 whitespace-nowrap text-[14px] font-medium"
          v-if="!$store.state.sidebarCollapsed">Games</span>
        <span class="bg-black-200 text-base-text-color flex-none px-1 py-1 rounded text-l" v-if="!$store.state.sidebarCollapsed">
          <BxSolidChevronUp class="hidden" />
          <BxSolidChevronDown />
        </span>
      </a>

      <!-- Sub Menu -->
      <ul id="dd-games" class="hidden">
        <li>
          <RouterLink to="/prediction-games"
            class="flex items-center h-[40px] p-3 text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg"
            data-tooltip-target="tooltip-123" data-tooltip-placement="right">
            <span class="flex-none text-xl mr-3 text-pf-yellow">
              <JaDices />
            </span>
            <span class="flex-1 whitespace-nowrap text-[14px] font-medium"
              v-if="!$store.state.sidebarCollapsed">Prediction Games</span>
          </RouterLink>
          <!-- tooltip -->
          <div id="tooltip-123" v-show="$store.state.sidebarCollapsed" role="tooltip"
            class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
            <p class="text-[13px]">Prediction Games</p>
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        </li>
        <li>
          <RouterLink to="/tournaments"
            class="flex items-center h-[40px] p-3 text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg"
            data-tooltip-target="tooltip-124" data-tooltip-placement="right">
            <span class="flex-none text-xl mr-3 text-pf-yellow">
              <BxSolidTrophy />
            </span>
            <span class="flex-1 whitespace-nowrap text-[14px] font-medium"
              v-if="!$store.state.sidebarCollapsed">Tournaments</span>
          </RouterLink>
          <!-- tooltip -->
          <div id="tooltip-124" v-show="$store.state.sidebarCollapsed" role="tooltip"
            class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
            <p class="text-[13px]">Tournaments</p>
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        </li>
        <li>
          <RouterLink to="/"
            class="flex items-center h-[40px] p-3 text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg"
            data-tooltip-target="tooltip-125" data-tooltip-placement="right">
            <span class="flex-none text-xl mr-3 text-pf-yellow">
              <LaHandPeace />
            </span>
            <span class="flex-1 whitespace-nowrap text-[14px] font-medium"
              v-if="!$store.state.sidebarCollapsed">Lottery</span>
          </RouterLink>
          <!-- tooltip -->
          <div id="tooltip-125" v-show="$store.state.sidebarCollapsed" role="tooltip"
            class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
            <p class="text-[13px]">Lottery</p>
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        </li>
      </ul>

      <!-- tooltip -->
      <div id="tooltip-games" v-show="$store.state.sidebarCollapsed" role="tooltip"
        class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
        <p class="text-[13px]">Games</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>
    <!-- Menu Item -->
    <li>
      <a class="flex items-center h-[40px] p-3 text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg cursor-pointer"
        aria-controls="dd-freegames" data-collapse-toggle="dd-freegames" data-tooltip-target="tooltip-fgames"
        data-tooltip-placement="right">
        <span class="flex-none text-xl mr-3 text-base-text-color">
          <IoGameController />
        </span>
        <span class="flex-1 whitespace-nowrap text-[14px] font-medium" v-if="!$store.state.sidebarCollapsed">Free
          Games</span>
        <span class="bg-black-200 text-base-text-color flex-none px-1 py-1 rounded text-l" v-if="!$store.state.sidebarCollapsed">
          <BxSolidChevronUp class="hidden" />
          <BxSolidChevronDown />
        </span>
      </a>

      <!-- Sub Menu -->
      <ul id="dd-freegames" class="hidden">
        <li>
          <RouterLink to="/"
            class="flex items-center h-[40px] p-3 text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg"
            data-tooltip-target="tooltip-126" data-tooltip-placement="right">
            <span class="flex-none text-xl mr-3 text-pf-yellow">
              <ReGameFill />
            </span>
            <span class="flex-1 whitespace-nowrap text-[14px] font-medium"
              v-if="!$store.state.sidebarCollapsed">Plinko</span>
          </RouterLink>
          <!-- tooltip -->
          <div id="tooltip-126" v-show="$store.state.sidebarCollapsed" role="tooltip"
            class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
            <p class="text-[13px]">Plinko</p>
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
        </li>
      </ul>

      <!-- tooltip -->
      <div id="tooltip-fgames" v-show="$store.state.sidebarCollapsed" role="tooltip"
        class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
        <p class="text-[13px]">Free Games</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>
    <!-- Menu Item -->
    <li>
      <a class="flex items-center p-3 h-[40px] text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg cursor-pointer"
        data-tooltip-target="tooltip-shop" data-tooltip-placement="right">
        <span class="flex-none text-xl mr-3 text-base-text-color">
          <AnFilledShop />
        </span>
        <span class="flex-1 whitespace-nowrap text-[14px] font-medium" v-if="!$store.state.sidebarCollapsed">Shop</span>
      </a>
      <!-- tooltip -->
      <div id="tooltip-shop" v-show="$store.state.sidebarCollapsed" role="tooltip"
        class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
        <p class="text-[13px]">Shop</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>
    <!-- Menu Item -->
    <li>
      <a class="flex items-center p-3 h-[40px] text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg cursor-pointer"
        data-tooltip-target="tooltip-loyalty" data-tooltip-placement="right">
        <span class="flex-none text-xl mr-3 text-base-text-color">
          <CoBadge />
        </span>
        <span class="flex-1 whitespace-nowrap text-[14px] font-medium" v-if="!$store.state.sidebarCollapsed">Loyalty
          Programme</span>
      </a>
      <!-- tooltip -->
      <div id="tooltip-loyalty" v-show="$store.state.sidebarCollapsed" role="tooltip"
        class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
        <p class="text-[13px]">Loyalty Programme</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>
    <!-- Menu Item -->
    <li>
      <a class="flex items-center p-3 h-[40px] text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg cursor-pointer"
        data-tooltip-target="tooltip-levelupadv" data-tooltip-placement="right">
        <span class="flex-none text-xl mr-3 text-base-text-color">
          <MiSolidShootingStar />
        </span>
        <span class="flex-1 whitespace-nowrap text-[14px] font-medium" v-if="!$store.state.sidebarCollapsed">Level
          Up & Adventure</span>
      </a>
      <!-- tooltip -->
      <div id="tooltip-levelupadv" v-show="$store.state.sidebarCollapsed" role="tooltip"
        class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
        <p class="text-[13px]">Level Up & Adventure</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>
    <!-- Menu Item -->
    <li>
      <a class="flex items-center p-3 h-[40px] text-black-100 hover:bg-black-400 hover:text-base-text-color rounded-lg cursor-pointer"
        data-tooltip-target="tooltip-leaderboard" data-tooltip-placement="right">
        <span class="flex-none text-xl mr-3 text-base-text-color">
          <IcLeaderboardStar />
        </span>
        <span class="flex-1 whitespace-nowrap text-[14px] font-medium"
          v-if="!$store.state.sidebarCollapsed">Leaderboard</span>
      </a>
      <!-- tooltip -->
      <div id="tooltip-leaderboard" v-show="$store.state.sidebarCollapsed" role="tooltip"
        class="fixed whitespace-nowrap z-10 invisible inline-block px-3 py-2 text-[13px] transition-opacity duration-300 bg-black-400 rounded-lg shadow-sm opacity-0 tooltip">
        <p class="text-[13px]">Leaderboard</p>
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'AppSidebar'
}
</script>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
import store from '../store/index.js'

// importing icons as SVG code
import { MdRoundGamepad } from '@kalimahapps/vue-icons';
import { IoGameController } from '@kalimahapps/vue-icons';
import { AnFilledShop } from '@kalimahapps/vue-icons';
import { CoBadge } from '@kalimahapps/vue-icons';
import { MiSolidShootingStar } from '@kalimahapps/vue-icons';
import { IcLeaderboardStar } from '@kalimahapps/vue-icons';
import { BxSolidChevronUp } from '@kalimahapps/vue-icons';
import { BxSolidChevronDown } from '@kalimahapps/vue-icons';
import { JaDices } from '@kalimahapps/vue-icons';
import { BxSolidTrophy } from '@kalimahapps/vue-icons';
import { LaHandPeace } from '@kalimahapps/vue-icons';
import { ReGameFill } from '@kalimahapps/vue-icons';

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();

  addEventListener("resize", (event) => {
    store.state.sidebarCollapsed = false;
  });
})
</script>

<style scoped></style>