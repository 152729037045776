<template>
  <aside id="app-sidebar"
    class="bg-header-color lg:rounded-lg text-base-text-color z-40 lg:transition-transform -translate-x-full lg:translate-x-0 max-lg:px-4 max-lg:py-1 max-lg:fixed max-lg:top-0 max-lg:left-0 max-lg:w-[100%] max-lg:h-full"
    :class="{ 'w-[70px]': $store.state.sidebarCollapsed }">
    <div class="h-full px-3 py-4 overflow-y-auto">

      <!-- Mobile Logo & Controls -->
      <div class="py-3 flex items-center justify-between lg:hidden">
        <a class="logo-light"><img src="../assets/logo-light.png" alt="" class="w-40" /></a>
        <a class="logo-dark"><img src="../assets/logo-dark.png" alt="" class="w-40" /></a>

        <button class="text-2xl bg-black-200 text-base-text-color rounded p-1" data-drawer-target="app-sidebar" data-drawer-toggle="app-sidebar">
          <HuTimes />
        </button>
      </div>

      <!-- Divider -->
      <span class="flex w-full h-[1px] my-3 bg-black-300 lg:hidden">&nbsp;</span>

      <!-- User Interaction Buttons -->
      <div class="flex justify-between py-3 items-center lg:hidden">
        <!-- User Info -->
        <section class="inline-flex flex-col">
          <div class="text-base-text-color text-[14px] font-bold pb-2">👋 Alex</div>
          <div class="relative">
            <span class="bg-black-400 flex items-center w-[120px] h-[15px] px-1 rounded-lg cursor-pointer">
              <span class="bg-black-200 block w-[100%] h-[7.5px] rounded-lg">
                <span class="bg-pf-yellow block w-[72%] h-[100%] rounded-lg relative">
                  <span
                    class="text-black-900 font-bold bg-pf-yellow w-[15px] h-[15px] text-[10px] text-center rounded-full absolute top-[-3px] right-[-10px]">7</span>
                </span>
              </span>
            </span>
          </div>
        </section>

        <!-- Divider -->
        <span class="flex w-[1px] h-[48px] bg-black-300 lg:hidden">&nbsp;</span>

        <div class="flex">
          <!-- Notifications Button -->
          <section class="inline-flex pr-3 items-center">
            <button class="bg-black-400 text-black-100 text-2xl px-3 py-3 rounded-lg relative">
              <BxSolidBellRing />
              <span
                class="absolute right-[-2.5px] top-[-2.5px] w-[10px] h-[10px] rounded-full bg-pf-yellow">&nbsp;</span>
            </button>
          </section>

          <!-- Account Button -->
          <section class="inline-flex items-center">
            <button class="bg-black-400 text-black-100 text-2xl px-3 py-3 rounded-lg">
              <McUser3Fill />
            </button>
          </section>
        </div>
      </div>

      <!-- Divider -->
      <span class="flex w-full h-[1px] my-3 bg-black-300 lg:hidden">&nbsp;</span>

      <!-- Navigation -->
      <SidebarNav />

      <!-- Divider -->
      <span class="flex w-full h-[1px] my-3 bg-black-300 lg:hidden">&nbsp;</span>

      <!-- User Info Details -->
      <div class="grid grid-cols-2 py-3 gap-5 lg:hidden">
        <!-- Points -->
        <section class="inline-flex items-center">
          <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg">
            <MdRocket />
          </button>

          <div class="flex flex-col pl-2">
            <span class="text-[16px] font-bold text-base-text-color">332</span>
            <span class="text-[13px] text-black-100">Points</span>
          </div>
        </section>

        <!-- Free Spins -->
        <section class="inline-flex items-center">
          <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg">
            <LuFerrisWheel />
          </button>

          <div class="flex flex-col pl-2">
            <span class="text-[16px] font-bold text-base-text-color">12</span>
            <span class="text-[13px] text-black-100">Free Spins</span>
          </div>
        </section>

        <!-- Free Tickets -->
        <section class="inline-flex items-center">
          <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg">
            <IoTicket />
          </button>

          <div class="flex flex-col pl-2">
            <span class="text-[16px] font-bold text-base-text-color">21</span>
            <span class="text-[13px] text-black-100">Free Tickets</span>
          </div>
        </section>

        <!-- Level -->
        <section class="inline-flex items-center">
          <button class="bg-black-400 text-pf-yellow text-2xl px-3 py-3 rounded-lg">
            <FlFilledCrown />
          </button>

          <div class="flex flex-col pl-2">
            <span class="text-[16px] font-bold text-base-text-color">17</span>
            <span class="text-[13px] text-black-100">Level</span>
          </div>
        </section>
      </div>

      <!-- Divider -->
      <span class="flex w-full h-[1px] my-3 bg-black-300 lg:hidden">&nbsp;</span>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'AppSidebar',
  components: {
    SidebarNav
  }
}
</script>

<script setup>
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
import store from '../store/index.js'
import SidebarNav from './SidebarNav.vue';

// importing icons as SVG code
import { HuTimes } from '@kalimahapps/vue-icons';
import { BxSolidBellRing } from '@kalimahapps/vue-icons';
import { McUser3Fill } from '@kalimahapps/vue-icons';
import { MdRocket } from '@kalimahapps/vue-icons';
import { LuFerrisWheel } from '@kalimahapps/vue-icons';
import { IoTicket } from '@kalimahapps/vue-icons';
import { FlFilledCrown } from '@kalimahapps/vue-icons';

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();

  addEventListener("resize", (event) => {
    store.state.sidebarCollapsed = false;
  });
})
</script>

<style scoped></style>